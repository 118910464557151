import * as React from "react";
import "./style.scss";

const TextContent = ({title, children}) => {
  return <div className="textcontent">
    <div className="textcontent-title">{title}</div>
    <div className="textcontent-body">
      {children}
    </div>
  </div>
}

export default TextContent;