import * as React from "react";

const LegalWarningEN = () => {
  return <div>
    <ul className={"ordered-text-items"}>
      <li>
        1. Market data is provided by Forinvest Yazılım ve Teknoloji Hizmetleri A.Ş. (Foreks), CoinMarketCap and <a
          href={"https://polygon.io"} target={"_blank"}>Polygon.io</a> Inc.; quoted prices
        are ‘last trade’ prices; BIST and Eurobond prices are quoted with 15 (fifteen) minutes delay.
      </li>
      <li>
        2. Presented current values of financial assets (calculated on ‘last trade’ prices), their trends or profit/loss calculations cannot be understood or interpreted as
        provision of investment consultancy service; such service can only be provided subject to an investment consultancy service agreement signed among the client and legally
        authorized institutions.
      </li>
      <li>
        3. Investment decisions based on presented current values of financial assets, their trends or profit/loss calculations may not provide expected outcome.
      </li>
      <li>
        4. Paramla Finansal Danışmanlık A.Ş. can by no means be held responsible for direct or indirect loss, loss of profit, intangible loss or any loss by third parties to be
        incurred due to incorrect or incomplete data provided by sources applied in running its web site or mobile apps or any use of data provided on its web site or mobile apps.
      </li>
    </ul>
  </div>
}

export default LegalWarningEN;
