import * as React from "react";
import {Helmet} from "react-helmet";
import Layout from "../components/Layout";
import Boxed from "../components/Boxed";
import TextContent from "../components/TextContent";
import LegalWarningEN from "content/LegalWarningEN";

const LegalWarningPage = () => {
  return <Layout>
    <Helmet>
      <meta charSet="utf-8"/>
      <title>Legal Warning | Paramla</title>
    </Helmet>
    <Boxed>
      <TextContent title="Legal Warning">
        <LegalWarningEN/>
      </TextContent>
    </Boxed>
  </Layout>
}

export default LegalWarningPage;